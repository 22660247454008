.divbackgroundScSc1x0n4hqIcon {
  position: relative;
  height: 100%;
  width: 100vw;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 0;
}

.videoBackground {
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it's behind all other content */
  pointer-events: none; /* Disable mouse and touch events */
}  
.beautifulButton {
  border-radius: 20px; /* Makes edges curved */
  background-color: beige; /* Sets the background color to beige */
  color: black; /* Sets the text color to black */
  font-weight: bold; /* Makes text bold */
}
.pseudo {
  flex: 1;
  position: relative;
  width: 10.38rem;
  height: 1rem;
}
.pleaseAnswerA {
  margin: 40;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(4, auto);  /* 4 rows */
  gap: 16px; /* Space between grid items */
  height: 200px;
}

.pleaseAnswerAContainer {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 1.75rem;
  font-weight: bolder;
  font-family: inherit;
}
.heading2 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-0) 0.15rem var(--padding-0) 0.16rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xl);
  max-width: 19.38rem;
}
.byStartingThis {
  position: relative;
  line-height: 1.5rem;
}
.terms {
  text-decoration: underline;
}
.divanimatestyledScScNw4u,
.ptextwrapperScSc1f8vz90 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ptextwrapperScSc1f8vz90 {
  padding: var(--padding-0) 0.21rem var(--padding-12xs) 0.22rem;
  box-sizing: border-box;
  align-items: center;
  max-width: 19.38rem;
  font-size: var(--font-size-base);
  color: var(--color-gray-500);
}
.divanimatestyledScScNw4u {
  align-items: flex-start;
  gap: var(--gap-2xs);
}
.divcontentwrapperinnerScS {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.label {
  position: relative;
  line-height: 1.75rem;
  display: inline-block;
  width: 18.75rem;
  height: 1.75rem;
  flex-shrink: 0;
}
.divscreencontentScSc1ndo,
.obFormslabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.obFormslabel {
  width: 18.75rem;
  height: 5.0rem;
  overflow: visible;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.divscreencontentScSc1ndo {
  height: 31.69rem;
  max-width: 45rem;
}
.divscreencontentwrapperSc {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-0) var(--padding-21xl);
  align-items: flex-start;
  justify-content: center;
}
.svgIcon {
  position: absolute;
  top: 0.31rem;
  left: calc(50% - 64.08px);
  width: 0.75rem;
  height: 0.75rem;
  overflow: hidden;
}
.takes4Minutes {
  position: absolute;
  top: 0.06rem;
  left: calc(50% - 48.4px);
  line-height: 1.25rem;
}
.start {
  position: relative;
}
.button {
  position: relative;
  top: 2rem;
  left: calc(50% - 44.72px);
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm-5) 1.16rem var(--padding-mini-5) 1.18rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
}
.divwelcomeScreenTimetocomp {
  position: relative;
  width: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divwelcomeScreenStickyfoot {
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 8.06rem;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
}
.bodyMain,
.interview,
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.section {
  flex-direction: column;
  z-index: 2;
}
.bodyMain,
.interview {
  position: relative;
  flex-direction: row;
  margin-top: 15px;
}
.bodyMain {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-gray-100);
  font-family: var(--font-montserrat);
  width: auto;
  bottom: 1rem;
}
.interview {
  border-radius: var(--br-5xs);
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-button {
  margin-bottom: 10px; /* Adjust the margin as needed */
}

@media screen and (max-width: 768px) {
  .button {
    position: relative;
    top: 0.5rem;
    left: calc(50% - 44.72px);
    border-radius: var(--br-9xs);
    background-color: var(--color-white);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    padding: var(--padding-sm-5) 1.16rem var(--padding-mini-5) 1.18rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
  }

  .obFormslabel {
    width: 18.75rem;
    height: 5rem;
    overflow: visible;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-black);
    font-family: var(--font-inter);
  }

  .divscreencontentScSc1ndo {
    top:10rem;
    height: 20.69rem;
    max-width: 45rem;
  }

  .pleaseAnswerA {
    margin: 10;
  }
}